enum ActionTypes {
    GET_LIST_DEVICE = 'settingsDevices/GET_LIST_DEVICE',
    UPDATE_LIST_DEVICE = 'settingsDevices/UPDATE_LIST_DEVICE',
    GET_DEVICES_BRANDS = 'settingsDevices/GET_DEVICES_BRANDS',
    UPDATE_DEVICES_BRANDS = 'settingsDevices/UPDATE_DEVICES_BRANDS',
    GET_DEVICE_INFO_BY_UNIQUE_ID = 'settingsDevices/GET_DEVICE_INFO_BY_UNIQUE_ID',
    SET_DEVICE_UNIQUE_ID = 'settingsDevices/SET_DEVICE_UNIQUE_ID',
    SET_DEVICE_INFO = 'settingsDevices/SET_DEVICE_INFO',
    SET_DEVICE_ID = 'settingsDevices/SET_DEVICE_ID',
    GET_DEVICE_BY_ID = 'settingsDevices/GET_DEVICE_BY_ID',
    UPDATE_DEVICE = 'settingsDevices/UPDATE_DEVICE',
    SET_DEVICE_CRUD_MODE = 'settingsDevices/SET_DEVICE_CRUD_MODE',
    UPDATE_DEVICE_BY_ATTRIBUTE = 'settingsDevices/UPDATE_DEVICE_BY_ATTRIBUTE',
    UPDATE_DEVICE_DATA = 'settingsDevices/UPDATE_DEVICE_DATA',
    DELETE_DEVICE = 'settingsDevices/DELETE_DEVICE'
}

export default ActionTypes;