import {useDispatch, useSelector} from "react-redux";
import {
    setInitServerConnection, setPendingRefreshToken,
    setServerConnectionStatus,
    setVehicleTailList,
    updateCurrentUser,
    updateCurrentUserByAttribute
} from "../shared/actions";
import {FormatNameObject, MapInteraction, MapSettings, UiInterface} from "./interface";
import {itemLocalStorage} from "./constants";
import React from "react";
import {Dispatch} from "redux";
import {createStructuredSelector} from "reselect";
import {
    makeSelectCurrentUser,
    makeSelectFreeze,
    makeSelectInitServerConnection, makeSelectPendingRefreshToken, makeSelectUserLoginStatus,
    makeSelectVehiclesTails
} from "../shared/selectors";
import {IVehicleTail} from "../models/IVehicleTail";

let storageServiceDispatchRef: Dispatch;
let storageServiceSelectorRef: any;
let httpRequestQueue: Map <string, any> = new Map();
export const StorageServiceDispatchConfigurator: React.FC = () => {
    storageServiceDispatchRef = useDispatch();
    storageServiceSelectorRef = useSelector(createStructuredSelector({
        vehicleTails: makeSelectVehiclesTails(),
        freeze: makeSelectFreeze(),
        currentUser: makeSelectCurrentUser(),
        INIT_SERVER_CONNECTION: makeSelectInitServerConnection(),
        pendingRefreshToken: makeSelectPendingRefreshToken(),
        USER_LOGIN_STATUS: makeSelectUserLoginStatus()
    }));
    return null
};

export class StorageService {
    public static changeLanguageSettings(storageType: string, language: any): void {
        switch (storageType) {
            case 'localStorage':
                localStorage.setItem(itemLocalStorage.userLang, language);
                break;
            case 'API': {
                const {currentUser} = storageServiceSelectorRef;
                if (currentUser?.id) {
                    localStorage.setItem("APP_LANGUAGE", language);
                    storageServiceDispatchRef(updateCurrentUserByAttribute({ k: 'language', settings: language}));
                    storageServiceDispatchRef(updateCurrentUser());
                    break;
                }
            }
        }
    }

    public static changeUIInterfaceSettings(storageType: string, settings: UiInterface): void {
        switch (storageType) {
            case 'localStorage':
                localStorage.setItem(itemLocalStorage.appUi, JSON.stringify(settings));
                break;
            case 'API': {
                const {currentUser} = storageServiceSelectorRef;
                if (currentUser?.id) {
                    storageServiceDispatchRef(updateCurrentUserByAttribute({ k: itemLocalStorage.appUi, settings}));
                    storageServiceDispatchRef(updateCurrentUser());
                    break;
                }
            }
        }
    }

    public static changeFormatNameObjectSettings(storageType: string, settings: FormatNameObject): void {
        switch (storageType) {
            case 'localStorage':
                localStorage.setItem(itemLocalStorage.appFormat, JSON.stringify(settings));
                break;
            case 'API': {
                const {currentUser} = storageServiceSelectorRef;
                if (currentUser?.id) {
                    storageServiceDispatchRef(updateCurrentUserByAttribute({ k: itemLocalStorage.appFormat, settings}));
                    storageServiceDispatchRef(updateCurrentUser());
                    break;
                }
            }
        }
    }

    public static changeMapSettings(storageType: string, settings: MapSettings): void {
        switch (storageType) {
            case 'localStorage':
                localStorage.setItem(itemLocalStorage.appMap, JSON.stringify(settings));
                break;
            case 'API': {
                const {currentUser} = storageServiceSelectorRef;
                if (currentUser?.id) {
                    storageServiceDispatchRef(updateCurrentUserByAttribute({ k: itemLocalStorage.appMap, settings}));
                    storageServiceDispatchRef(updateCurrentUser());
                }
                break;
            }
        }
    }

    public static changeMapInteraction(storageType: string, settings: MapInteraction): void {
        switch (storageType) {
            case 'localStorage': {
                const {currentUser} = storageServiceSelectorRef;
                if (currentUser?.id) localStorage.setItem(`${itemLocalStorage.appMapInteraction}${currentUser.id}`, JSON.stringify(settings));
                break;
            }
            case 'API':
                break;
        }
    }

    public static getStorageVehicleTails(): any {
        const { vehicleTails } = storageServiceSelectorRef;
        return vehicleTails;
    }

    public static getStorageFreeze(): any {
        const { freeze } = storageServiceSelectorRef;
        return freeze;
    }

    public static setPendingRefreshToken(isRefresh: boolean): any {
        storageServiceDispatchRef(setPendingRefreshToken(isRefresh));
    }

    public static getPendingRefreshToken(): any {
        const { pendingRefreshToken } = storageServiceSelectorRef;
        return pendingRefreshToken;
    }

    public static updateStorageVehicleTails(): void {
        const { vehicleTails } = storageServiceSelectorRef;
        const timestamp = Date.now() - 90000;
        const tempTails: any = [];
        vehicleTails.forEach((item: IVehicleTail) => {
            item.positions = [...item.positions.filter(pos => pos.timestamp > timestamp)];
            if (item.positions.length) {
                tempTails.push(item);
            }
        });
        storageServiceDispatchRef(setVehicleTailList([...tempTails]));
    }

    public static setServerConnectStatus(status: number): any {
        storageServiceDispatchRef(setServerConnectionStatus(status));
        const { INIT_SERVER_CONNECTION } = storageServiceSelectorRef;
        if (!INIT_SERVER_CONNECTION) storageServiceDispatchRef(setInitServerConnection(true));
    }

    public static pushRequestToQueue(key: string, req: any): any {
        httpRequestQueue.set(key, req);
    }

    public static shiftRequestToQueue(): any {
        httpRequestQueue.forEach((request) => {
            storageServiceDispatchRef(request);
        });
        httpRequestQueue = new Map();
    }

    public static clearRequestQueue(): any {
        httpRequestQueue = new Map();
    }

    public static getAccessToken(): string | null {
        const { USER_LOGIN_STATUS } = storageServiceSelectorRef;
        if (!USER_LOGIN_STATUS) return localStorage.getItem("token");
        else return sessionStorage.getItem("token");
    }

    public static getAccessRefreshToken(): string | null {
        const { USER_LOGIN_STATUS } = storageServiceSelectorRef;
        if (!USER_LOGIN_STATUS) return localStorage.getItem("refreshToken");
        else return sessionStorage.getItem("refreshToken");
    }

    public static getAccessEventsToken(): string | null {
        const { USER_LOGIN_STATUS } = storageServiceSelectorRef;
        if (!USER_LOGIN_STATUS) return localStorage.getItem("eventsToken");
        else return sessionStorage.getItem("eventsToken");
    }

    public static getAccessModules(): string | null {
        const { USER_LOGIN_STATUS } = storageServiceSelectorRef;
        if (!USER_LOGIN_STATUS) return localStorage.getItem("modules");
        else return sessionStorage.getItem("modules");
    }

    public static getAccessPrivileges(): string | null {
        const { USER_LOGIN_STATUS } = storageServiceSelectorRef;
        if (!USER_LOGIN_STATUS) return localStorage.getItem("privileges");
        else return sessionStorage.getItem("privileges");
    }
}
