// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".__qjhdeasBU8vWXG3YLH {\n    top: -18px!important;\n    right: 11px!important;\n}\n\n.MaVxHr9h4leyBkUq7naQ {\n    top: 44px!important;\n    left: 11px!important;\n}", "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;AACxB","sourcesContent":[".notificationTopFirst {\n    top: -18px!important;\n    right: 11px!important;\n}\n\n.notificationTopSecond {\n    top: 44px!important;\n    left: 11px!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationTopFirst": "__qjhdeasBU8vWXG3YLH",
	"notificationTopSecond": "MaVxHr9h4leyBkUq7naQ"
};
export default ___CSS_LOADER_EXPORT___;
