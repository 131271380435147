import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import styles from "./App.module.css";

const Layout = React.lazy(() => LazyLoadingHelper.lazyReloadOnFail(() => import("./layouts/Layout")));
const Login = React.lazy(() => LazyLoadingHelper.lazyReloadOnFail(() => import("./pages/Login")));
const Reset = React.lazy(() => LazyLoadingHelper.lazyReloadOnFail(() => import("./pages/Reset")));
const RestorePassword = React.lazy(() => LazyLoadingHelper.lazyReloadOnFail(() => import("./pages/RestorePassword")));
import { createStructuredSelector } from "reselect";
import { makeSelectLanguages, makeSelectLoadingFile } from "./shared/selectors";
import { useSelector } from "react-redux";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import IOSOrientation from "./services/observable/IOSOrientation";
import LanguageService from "./services/lang/LanguageService";
import Page404 from "./pages/Page404";
import SentryInitService from "./services/sentry/SentryInitService";
import { SnackbarProvider } from "notistack";
import { SnackbarCloseButton } from "./components/clickable/SnackbarCloseButton";
import { NotificationServiceConfigurator } from "./services/notification/NotificationService";
import { StorageServiceDispatchConfigurator } from "./settings/storageService";
import ViewPortProvider from "./providers/ViewPortProvider";
import {LazyLoadingHelper} from "./helpers/LazyLoadingHelper";

const stateSelector = createStructuredSelector({
    languages: makeSelectLanguages(),
    loadingFile: makeSelectLoadingFile(),
});

function App() {
    const { languages, loadingFile } = useSelector(stateSelector);
    useEffect(() => {
        i18n.use(initReactI18next).init({
            resources: !languages || !languages.length ? LanguageService.checkBrowserLanguage() :
                LanguageService.getResources(languages),
            fallbackLng: LanguageService.getDefaultLanguage(),
            defaultNS: "translation",
            interpolation: {
                escapeValue: false,
            },
        });
    }, [languages]);

    useEffect(() => {
        IOSOrientation.setOrientationListener();
        IOSOrientation.setResizeListener();
        SentryInitService.init();
        // serviceWorkerRegistration();
    }, []);

    // const serviceWorkerRegistration = () => {
    //     if ('serviceWorker' in navigator && 'PushManager' in window) {
    //         navigator.serviceWorker.register("/service-worker.js")
    //             .then((swReg) => {
    //                 console.log('Service Worker is registered', swReg);
    //             })
    //             .catch((error) => {
    //                 console.error('Service Worker Error', error);
    //             });
    //     }
    // }

    return (
        <ViewPortProvider>
            <SnackbarProvider
                action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey}/>}
                classes={{
                    root: !loadingFile ? styles.notificationTopFirst : styles.notificationTopSecond,
                }}
            >
                <NotificationServiceConfigurator/>
                <StorageServiceDispatchConfigurator/>
                <Suspense fallback={<></>}>
                    <Routes>
                        <Route path="/login" element={<Login/>}/>
                        {/*<Route path="/join" element={<Join/>}/>*/}
                        <Route path="/reset-password/:id" element={<RestorePassword/>}/>
                        <Route path="/reset" element={<Reset/>}/>
                        <Route path="/page-404" element={<Page404/>}/>
                        <Route path="*" element={<Layout/>}/>
                    </Routes>
                </Suspense>
            </SnackbarProvider>
        </ViewPortProvider>
        // <Suspense fallback={<></>}>
        //     <Routes>
        //         <Route path="/login" element={<Login/>}/>
        //         {/*<Route path="/join" element={<Join/>}/>*/}
        //         <Route path="/reset-password/:id" element={<RestorePassword/>}/>
        //         <Route path="/reset" element={<Reset/>}/>
        //         <Route path="/page-404" element={<Page404/>}/>
        //         <Route path="*" element={<Layout/>}/>
        //     </Routes>
        // </Suspense>
    );
}

export default App;
