import React, { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import { Box, SnackbarContent } from "@mui/material";
import { createStructuredSelector } from "reselect";
import {
    makeSelectImportListInProgress,
    makeSelectImportProgress,
    makeSelectLoadingFile,
} from "../../shared/selectors";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { setImportListInProgress, setLoadingFile, setOpenImportList } from "../../shared/actions";
import { Watch } from "react-loader-spinner";

const stateSelector = createStructuredSelector({
    loadingFile: makeSelectLoadingFile(),
    importProgress: makeSelectImportProgress(),
    importListInProgress: makeSelectImportListInProgress(),
});

const DownloadNotification = () => {
    const { loadingFile, importProgress, importListInProgress } = useSelector(stateSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        if (importProgress?.model) {
            updateList(importProgress?.model);
        }
    }, [importProgress]);

    const updateList = (model: any) => {
        // const imports: any[] = [];
        if (!model?.finished) {
            if (model?.process?.part !== "Delete old data" && model?.process) {
                const imports: any[] = [...importListInProgress];
                imports.forEach(item => {
                    if (item.part === model.process.part) {
                        item.pos = model.process.pos;
                        item.size = model.process.size;
                    }
                });
                dispatch(setImportListInProgress([...imports]));
            }
        } else if (model?.finished) {
            const filtered = importListInProgress.filter(item => item.size !== item.pos);
            dispatch(setImportListInProgress(filtered));
            // if (!filtered.length) {
            //     dispatch(setLoadingFile(false));
            // }
        }
    };

    const onClick = () => {
        dispatch(setOpenImportList(true));
        if (!importListInProgress.length) {
            dispatch(setLoadingFile(false));
        }
    };

    return (
        <Box
            sx={{
                "& .MuiSnackbar-root": {
                    padding: "0px",
                    top: "8px",
                    right: "8px",
                },
            }}
        >
            <Snackbar
                open={loadingFile}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClick={onClick}
            >
                <SnackbarContent
                    sx={{
                        backgroundColor: "#673ab7!important",
                        cursor: "pointer",
                        height: "38px",
                        padding: "0 12px",
                        "& .MuiSnackbarContent-message": {
                            padding: "0px",
                        },
                    }}
                    message={
                        <div className="column-alignment-start">
                            <div className="row-alignment-start">
                                {
                                    importListInProgress.length ?
                                        <Watch
                                            height="32"
                                            width="32"
                                            radius="36"
                                            color="#FFFFFF"
                                            visible={true}
                                        />
                                        :
                                        null
                                }
                                <div style={{
                                    maxWidth: "256px", whiteSpace: "nowrap", overflow: "hidden",
                                    textOverflow: "ellipsis", marginLeft: "8px", marginTop: "6px",
                                }}>
                                    {i18next.t(importListInProgress.length ? "settings.object.import_file" : "settings.object.import_finished")}
                                </div>
                            </div>
                        </div>
                    }
                />
            </Snackbar>
        </Box>
    );
};

export default DownloadNotification;