import {IKeyValue} from "../../models/IKeyValue";

export default {

    isEmpty(obj: any): boolean {
        return Object.keys(obj).length === 0;
    },

    isEqual(a: any, b: any): boolean {
        return JSON.stringify(a) === JSON.stringify(b);
    },

    getDeepCloneObject(obj: any) {
        return JSON.parse(JSON.stringify(obj));
    },

    flattenObject(obj: any, parentKey = "", result: IKeyValue[] = []): IKeyValue[] {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const value = obj[key];
                const currentKey = parentKey ? `${parentKey}.${key}` : key;

                if (Array.isArray(value)) {
                    for (let i = 0; i < value.length; i++) {
                        const arrayKey = `${currentKey}[${i}]`;
                        result.push({key: arrayKey, value: value[i]});
                    }
                } else if (typeof value === "object" && value !== null) {
                    this.flattenObject(value, currentKey, result);
                } else {
                    result.push({key: currentKey, value: value});
                }
            }
        }

        return result;
    },

    convertObjectsArrayToPairObjectsArray(arr: IKeyValue []): [IKeyValue, IKeyValue] [] {
        const arrayOfArrays: any [] = [];

        for (let i = 0; i < arr.length; i += 2) {
            const pair = arr.slice(i, i + 2);
            arrayOfArrays.push(pair);
        }
        return arrayOfArrays;
    },

    areArraysEqual(arr1: number [], arr2: number []): boolean {
        if (!arr1 && !arr2) return true;
        else if (!arr1 || !arr2) return false;
        const sortedArr1 = arr1.sort((a, b) => a - b);
        const sortedArr2 = arr2.sort((a, b) => a - b);

        if (sortedArr1.length !== sortedArr2.length) {
            return false;
        }

        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }

        return true;
    }
};
