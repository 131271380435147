import { menuLeftTabs } from '../../config';
import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import PermissionStorage from "../../../../services/permission/PermissionStorage";
import {DriverInfo, UserInfo} from "../../interface";

export const initialState: ContainerState = {
    loadUser: false,
    loadDriver: false,
    loadCar: false,
    loadAccess: false,
    loadEditCreate: false,
    showBottomBlock: false,
    leftMenuItem: menuLeftTabs[0].value,
    rightMenuItem: menuLeftTabs[0].menuRightTab,
    userTypeRight: "user.view",
    driverTypeRight: "driver.empty",
    successCreateUser: false,
    userId: undefined,
    driverId: undefined,
    listUser: [],
    selectUser: {},
    errorValid: {},
    selectDriver: {},
    listAccessCar: [],
    listAccessDriver: [],
    listDriver: [],
    tableRightView: [],
    tableRightChange: [],
    tableRightElementTrack: [],
    tableRightControl: [],
    linkedVehicleListByDriverId: [],
    userListSort: "name",
    listDriverQueryParams: undefined,
    userDealer: undefined
};

function settingsReducer(
    state: ContainerState = initialState,
    action: ContainerActions,
): ContainerState {
    switch (action.type) {
        case ActionTypes.SET_SORT_USER_LIST:
            return {
                ...state,
                userListSort: action.payload,
            };
        case ActionTypes.UPDATE_LOAD:
            return {
                ...state,
                loadEditCreate: action.payload,
            };
        case ActionTypes.CREATE_UPDATE_USER:
        case ActionTypes.UPDATE_ACCESS_USER:
            return {
                ...state,
                loadEditCreate: true,
            };
        case ActionTypes.GET_LIST_USER:
            return {
                ...state,
                loadUser: false,
            };
        case ActionTypes.GET_INFO_ACCESS_USER:
            return {
                ...state,
                loadDriver: false,
                loadCar: false,
                loadAccess: false,
            };
        case ActionTypes.CLEAR_STATE: {
            const reset: any = {};
            for (const i in initialState) {
                if (state[i as keyof typeof state]) {
                    reset[i as keyof typeof reset] = initialState[i as keyof typeof initialState]
                }
            }
            return {
                ...reset
            }
        }
        case ActionTypes.SWITCH_LEFT_MENU_ITEM: {
            let index = 0;
            menuLeftTabs.some((item, i) => { if (item.value === action.payload) return (index = i); });
            const rightMenuItem = menuLeftTabs[index].menuRightTab;
            return {
                ...state,
                leftMenuItem: action.payload,
                showBottomBlock: false,
                rightMenuItem
            };
        }
        case ActionTypes.SWITCH_RIGHT_MENU_ITEM: {
            const _showBottomBlock = action.payload === "access";
            return {
                ...state,
                showBottomBlock: _showBottomBlock,
                rightMenuItem: action.payload,
            };
        }
        case ActionTypes.UPDATE_USER_INFO: {
            const { id, value } = action.payload;
            const { selectUser } = state;
            selectUser[id as keyof UserInfo] = value;
            if (state.errorValid[id as keyof UserInfo]) {
                delete state.errorValid[id as keyof UserInfo];
            }
            return {
                ...state,
                selectUser: Object.assign({}, selectUser),
            };
        }
        case ActionTypes.UPDATE_DRIVER_INFO: {
            const { selectDriver } = state;
            selectDriver[action.payload.id as keyof DriverInfo] = action.payload.value;
            if (state.errorValid[action.payload.id as keyof DriverInfo]) {
                delete state.errorValid[action.payload.id as keyof DriverInfo];
            }
            return {
                ...state,
                selectDriver: Object.assign({}, selectDriver),
            };
        }
        case ActionTypes.UPDATE_LIST_USER: {
            // const userTypeRight = action.payload?.length ? "user.view" : "user.add";
            // let user = !state.selectUser?.id && action.payload?.length ? action.payload[0] : initialState.selectUser;
            // if (state.selectUser?.id && action.payload?.length) {
            //     user = action.payload.filter((item: any) => item.id === state.selectUser?.id)[0];
            // } else if (action.payload?.length && typeof action.meta === "string") {
            //     user = action.payload.filter((item: any) => item.email === action.meta)[0];
            // }
            // if (user?.phone?.length) {
            //     user.phone1 = user?.phone[0];
            //     if (user?.phone[1]) {
            //         user.phone2 = user?.phone[1];
            //     }
            // }
            return {
                ...state,
                listUser: action.payload,
                // userTypeRight,
                // selectUser: Object.assign({}, user),
                loadUser: true,
                loadEditCreate: initialState.loadEditCreate,
                // errorValid: initialState.errorValid,
            };
        }
        case ActionTypes.SET_USER_ID: {
            return {
                ...state,
                userId: action.payload,
            };
        }
        case ActionTypes.SELECT_USER: {
            const _user = action.payload;
            if (_user?.phone?.length) {
                _user.phone1 = _user?.phone[0];
                if (_user?.phone[1]) {
                    _user.phone2 = _user?.phone[1];
                }
            }
            return {
                ...state,
                selectUser: Object.assign({}, _user),
                errorValid: initialState.errorValid,
                userTypeRight: "user.view",
                tableRightView: initialState.tableRightView,
                tableRightChange: initialState.tableRightChange,
                tableRightControl: initialState.tableRightControl,
                tableRightElementTrack: initialState.tableRightElementTrack,
                loadAccess: false,
                loadEditCreate: initialState.loadEditCreate
            };
        }
        case ActionTypes.UPDATE_INFO_ACCESS_USER: {
            const { /*listCar,*/ listDriver, rightView, rightChange, rightControl, rightTrack } = action.payload;
            return {
                ...state,
                // listAccessCar: listCar,
                listAccessDriver: listDriver,
                tableRightView: rightView || initialState.tableRightView,
                tableRightChange: rightChange || initialState.tableRightChange,
                tableRightControl: rightControl || initialState.tableRightControl,
                tableRightElementTrack: rightTrack || initialState.tableRightElementTrack,
                loadDriver: true,
                loadCar: true,
                loadAccess: true
            };
        }
        case ActionTypes.SET_USER_CARS: {
            return {
                ...state,
                listAccessCar: action.payload
            };
        }
        case ActionTypes.UPDATE_LIST_DRIVER: {
            const listDriver = action.payload;
            const driverTypeRight = listDriver?.length ? "driver.view" : "driver.empty";
            let driverId = state?.driverId;
            let driver = undefined; // !state.selectDriver?.id && action.payload?.length ? action.payload[0] : initialState.selectDriver;
            if (listDriver?.length) {
                if (driverId) {
                    driver = listDriver.find((item: any) => item.id === driverId);
                } else {
                    driver = listDriver[0];
                    driverId = driver.id;
                }
            }
            if (driver?.phone?.length) {
                driver.phone1 = driver?.phone[0];
                if (driver?.phone[1]) {
                    driver.phone2 = driver?.phone[1];
                }
            }

            return {
                ...state,
                listDriver,
                driverId,
                driverTypeRight,
                selectDriver: Object.assign({}, driver),
            }
        }
        case ActionTypes.SET_FULL_ACCESS: {
            const isFullAccess = action.payload;
            const permissions = PermissionStorage.getUserPermissions();
            state.tableRightView.map((item: any) => {
                if (item.privileges[item.code] && permissions.includes(item.code)) {
                    item["privileges" as keyof typeof item][item.code].enabled = isFullAccess;
                }
                return item;
            });
            state.tableRightControl.map((item: any) => {
                item.codes.map((privilege: any) => {
                    if (item.privileges[privilege]) {
                        item["privileges" as keyof typeof item][privilege].enabled = isFullAccess;
                    }
                    return privilege;
                });
                return item;
            });
            state.tableRightElementTrack.map((item: any) => {
                item.codes.map((privilege: any) => {
                    if (item.privileges[privilege]) {
                        item["privileges" as keyof typeof item][privilege].enabled = isFullAccess;
                    }
                    return privilege;
                });
                return item;
            });
            state.tableRightChange.map((item: any) => {
                item.codes.map((privilege: any) => {
                    if (item.privileges[privilege] && permissions.includes(item.module)) {
                        item["privileges" as keyof typeof item][privilege].enabled = isFullAccess;
                    }
                    return privilege;
                });
                Object.assign(item, { disabled: false });
                return item;
            });
            return {
                ...state
            }
        }
        case ActionTypes.SET_VIEW_ACCESS: {
            const isViewAccess = action.payload;
            const regex = new RegExp(".view");
            const permissions = PermissionStorage.getUserPermissions();
            state.tableRightView.map((item: any) => {
                if (item.privileges[item.code] && permissions.includes(item.code)) {
                    item["privileges" as keyof typeof item][item.code].enabled = !(item.code === "admin" || item.code === "setup") && isViewAccess;
                }
                return item;
            });
            state.tableRightControl.map((item: any) => {
                item.codes.map((privilege: any) => {
                    if (item.privileges[privilege]) {
                        item["privileges" as keyof typeof item][privilege].enabled = false;
                    }
                    return privilege;
                });
                return item;
            });
            state.tableRightElementTrack.map((item: any) => {
                item.codes.map((privilege: any) => {
                    if (item.privileges[privilege]) {
                        item["privileges" as keyof typeof item][privilege].enabled = isViewAccess;
                    }
                    return privilege;
                });
                return item;
            });
            state.tableRightChange.map((item: any) => {
                item.codes.map((privilege: any) => {
                    if (item.privileges[privilege] && permissions.includes(item.module)) {
                        item["privileges" as keyof typeof item][privilege].enabled = regex.test(privilege) ? isViewAccess : false;
                    }
                    return privilege;
                });
                Object.assign(item, { disabled: false });
                if (item.id === "add_edit_customers" ||
                    item.id === "add_edit_users" ||
                    item.id === "add_edit_drivers" ||
                    item.id === "add_edit_objects" ||
                    item.id === "add_edit_sensors" ||
                    item.id === "add_edit_groups" ||
                    item.id === "add_edit_commands" ||
                    item.id === "add_edit_trackers" ||
                    item.id === "add_edit_tracks" ||
                    item.id === "add_edit_tariffs"
                ) {

                    Object.assign(item, { disabled: true });
                }
                return item;
            });
            return {
                ...state
            }
        }
        case ActionTypes.SET_DRIVER_ID: {
            return {
                ...state,
                driverId: action.payload,
            };
        }
        case ActionTypes.SELECT_DRIVER: {
            const driverTemp = action.payload;
            if (driverTemp?.phone?.length) {
                driverTemp.phone1 = driverTemp.phone[0];
                if (driverTemp.phone.length > 1) driverTemp.phone2 = driverTemp.phone[1];
            }
            return {
                ...state,
                // errorValid: initialState.errorValid,
                selectDriver: Object.assign({}, driverTemp),
                // driverTypeRight: "driver.view"
            }
        }
        case ActionTypes.ADD_DRIVER:
            return {
                ...state,
                selectDriver: {phone: []},
                // driverTypeRight: "driver.add"
            };
        case ActionTypes.ADD_USER:
            return {
                ...state,
                selectUser: {phone: []},
                // tableRightView: initialState.tableRightView,
                // tableRightChange: initialState.tableRightChange,
                // tableRightControl: initialState.tableRightControl,
                // tableRightElementTrack: initialState.tableRightElementTrack,
                // userTypeRight: "user.add",
                // loadEditCreate: initialState.loadEditCreate,
            };
        case ActionTypes.UPDATE_ACCESS: {
            const { tableName, checked, key, idChecked } = action.payload;
            switch (tableName) {
                case "tableRightChange":
                    state.tableRightChange[key as keyof typeof state.tableRightChange]["privileges"][idChecked]["enabled"] = checked;
                    break;
                case "tableRightControl":
                    state.tableRightControl[key as keyof typeof state.tableRightControl]["privileges"][idChecked]["enabled"] = checked;
                    break;
                case "tableRightElementTrack":
                    state.tableRightElementTrack[key as keyof typeof state.tableRightElementTrack]["privileges"][idChecked]["enabled"] = checked;
                    break;
                case "tableRightView":
                    state.tableRightView[key as keyof typeof state.tableRightView]["privileges"][idChecked]["enabled"] = checked;
                    break;
            }
            return {
                ...state
            }
        }
        case ActionTypes.SELECT_DRIVER_ACCESS: {
            if (action.payload.key === "all") {
                state.listAccessDriver.map((driver: any) => {
                    return Object.assign(driver, { checked: action.payload.checked });
                });
            } else {
                state.listAccessDriver[action.payload.key as keyof typeof state.listAccessDriver]["checked"] = action.payload.checked;
            }

            return {
                ...state
            }
        }
        case ActionTypes.RESPONSE_CREATE_USER:
            return {
                ...state,
                successCreateUser: action.payload,
            };
        case ActionTypes.UPDATE_LINKED_VEHICLE_LIST_BY_DRIVER_ID:
            return {
                ...state,
                linkedVehicleListByDriverId: action.payload
            };
        case ActionTypes.SET_LIST_DRIVER_QUERY_PARAMS:
            return {
                ...state,
                listDriverQueryParams: action.payload,
            };
        case ActionTypes.SET_USER_DEALER: {
            return {
                ...state,
                userDealer: action.payload,
            };
        }
        default:
            return state;
    }
}

export default settingsReducer;