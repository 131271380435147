import React, {useEffect, useRef, useState} from "react";
import Snackbar from '@mui/material/Snackbar';
import {SnackbarContent} from "@mui/material";
import {createStructuredSelector} from "reselect";
import {
    makeSelectServerConnectionStatus
} from "../../shared/selectors";
import {useDispatch, useSelector} from "react-redux";
import {sseSubscribe} from "../../shared/actions";
import i18next from "i18next";

const stateSelector = createStructuredSelector({
    SERVER_CONNECTION_STATUS: makeSelectServerConnectionStatus()
});

const InterruptedConnectionNotification = () => {
    const {SERVER_CONNECTION_STATUS} = useSelector(stateSelector);

    const dispatch = useDispatch();

    const [isInit, setIsInit] = useState(false);
    const [open, setOpen] = useState(false);
    const [count, setCount] = useState(2);
    const [step, setStep] = useState(0);
    const status = useRef(0);

    useEffect(() => {
        status.current = SERVER_CONNECTION_STATUS;
        if ((!SERVER_CONNECTION_STATUS && isInit) || SERVER_CONNECTION_STATUS === 2) {
            setTimeout(() => {
                setOpen(!status.current || status.current === 2);
            }, 500)
        } else {
            setIsInit(true);
            setOpen(false);
            setCount(2);
            setStep(0);
        }
    }, [SERVER_CONNECTION_STATUS]);

    useEffect(() => {
        let interval: any;
        if (open) {
            interval = setInterval(() => {
                if (count > 1) setCount(count - 1);
                else {
                    dispatch(sseSubscribe());
                    updateCounter();
                }

            }, 1000);
        }
        return () => clearInterval(interval);
    }, [count, open]);

    const updateCounter = () => {
        if (!step) {
            setStep(step + 1);
            setCount(5);
        } else if (step === 1) {
            setStep(step + 1);
            setCount(30);
        } else setCount(30);
    };

    const handleClick = () => {
        dispatch(sseSubscribe());
        updateCounter();
    };

    return (
        <div>
            <Snackbar
                open={open}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <SnackbarContent
                    sx={{backgroundColor: '#ff6500!important', marginTop: '-16px', marginRight: '-16px'}}
                    message={
                        <div className="row-alignment-start">
                            <div>{i18next.t( count > 29 ? "sse.connection_reconnect" : "sse.connection_timeout", {count})}</div>
                            <div style={{textDecoration: 'underline', cursor: 'pointer', padding: '0 12px'}} onClick={handleClick}>{i18next.t("sse.connection_try_now")}</div>
                        </div>
                    }
                />
            </Snackbar>
        </div>
    );
};

export default InterruptedConnectionNotification;