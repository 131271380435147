import { createSelector } from 'reselect';
import { initialState } from './reducer';
import {ApplicationRootState} from '../services/types';

const selectShared = (state: ApplicationRootState) => state.shared || initialState;

const makeSelectLanguages = () =>
    createSelector(selectShared, substate => substate.languages);

const makeSelectUserLoginStatus = () =>
    createSelector(selectShared, substate => substate.USER_LOGIN_STATUS);

const makeSelectServerConnectionStatus = () =>
    createSelector(selectShared, substate => substate.SERVER_CONNECTION_STATUS);

const makeSelectInitServerConnection = () =>
    createSelector(selectShared, substate => substate.INIT_SERVER_CONNECTION);

const makeSelectCurrentUser = () =>
    createSelector(selectShared, substate => substate.currentUser);

const makeSelectCurrentUserGeolocation = () =>
    createSelector(selectShared, substate => substate.currentUserGeolocation);

const makeSelectCurrentCustomer = () =>
    createSelector(selectShared, substate => substate.currentCustomer);

const makeSelectVehicles = () =>
    createSelector(selectShared, substate => substate.vehicleList);

const makeSelectVehiclesParams = () =>
    createSelector(selectShared, substate => substate.vehicleListParams);

const makeSelectVehicleId = () =>
    createSelector(selectShared, substate => substate.vehicleId);

const makeSelectVehiclesTails = () =>
    createSelector(selectShared, substate => substate.vehicleTailList);

const makeSelectVehicle = () =>
    createSelector(selectShared, substate => substate.vehicle);

const makeSelectSwipedVehicle = () =>
    createSelector(selectShared, substate => substate.swipedVehicle);

const makeSelectVehicleDriverList = () =>
    createSelector(selectShared, substate => substate.vehicleDriverList);

const makeSelectTags = () =>
    createSelector(selectShared, substate => substate.tagList);

const makeSelectVehicleTagParams = () =>
    createSelector(selectShared, substate => substate.vehicleTagParams);

const makeSelectTag = () =>
    createSelector(selectShared, substate => substate.tag);

const makeSelectDeleteTagId = () =>
    createSelector(selectShared, substate => substate.deleteTagId);

const makeSelectIsOpenTags = () =>
    createSelector(selectShared, substate => substate.isOpenTags);

const makeSelectIsOpenGroups = () =>
    createSelector(selectShared, substate => substate.isOpenGroups);

const makeSelectLoading = () =>
    createSelector(selectShared, substate => substate.loading);

const makeSelectFreeze = () =>
    createSelector(selectShared, substate => substate.freeze);

const makeSelectExpand = () =>
    createSelector(selectShared, substate => substate.expand);

const makeGetVehicleAddress = () =>
    createSelector(selectShared, substate => substate.vehicleAddress);

const makeGetVehicleCurrentPosition = () =>
    createSelector(selectShared, substate => substate.vehicleCurrentPosition);

const makeSelectTrackUUID = () =>
    createSelector(selectShared, substate => substate.trackUUID);

const makeSelectVehiclesTracks = () =>
    createSelector(selectShared, substate => substate.vehiclesTracks);

const makeSelectTrackParams = () =>
    createSelector(selectShared, substate => substate.trackParams);

const makeSelectEmptyTrack = () =>
    createSelector(selectShared, substate => substate.emptyTrack);

const makeSelectVisibleStream = () =>
    createSelector(selectShared, substate => substate.visibleStream);

const makeSelectVisibleMonitoringInfo = () =>
    createSelector(selectShared, substate => substate.visibleMonitoringInfo);

const makeSelectLastPingTimestamp = () =>
    createSelector(selectShared, substate => substate.lastPingTimestamp);

const makeSelectGeofenceList = () =>
    createSelector(selectShared, substate => substate.geofenceList);

const makeSelectGeofenceParams = () =>
    createSelector(selectShared, substate => substate.geofenceListParams);

const makeSelectGeofence = () =>
    createSelector(selectShared, substate => substate.geofence);

const makeSelectGeofenceCRUDMode = () =>
    createSelector(selectShared, substate => substate.geofence_CRUD_Mode);

const makeSelectStartDrawGeofence = () =>
    createSelector(selectShared, substate => substate.startDrawGeofence);

const makeSelectVisibleGeofenceInfo = () =>
    createSelector(selectShared, substate => substate.visibleGeofenceInfo);

const makeSelectVehicleGeofenceList = () =>
    createSelector(selectShared, substate => substate.vehicleGeofenceList);

const makeSelectEditGeofenceId = () =>
    createSelector(selectShared, substate => substate.editGeofenceId);

const makeSelectIsEditGeofence = () =>
    createSelector(selectShared, substate => substate.isEditGeofence);

const makeSelectVehicleListAttachedToGeofence = () =>
    createSelector(selectShared, substate => substate.vehicleListAttachedToGeofence);

const makeSelectVehicleAmountInsideOutsideToGeofence = () =>
    createSelector(selectShared, substate => substate.vehicleAmountInsideOutsideToGeofence);

const makeSelectEditedGeofenceRadius = () =>
    createSelector(selectShared, substate => substate.editedGeofenceRadius);

const makeSelectCheckedVehiclesPositions = () =>
    createSelector(selectShared, substate => substate.checkedVehiclesPositions);

const makeSelectCheckedVehiclesPositionsParams = () =>
    createSelector(selectShared, substate => substate.checkedVehiclesPositionsParams);

const makeSelectLoadingVehiclesPositions = () =>
    createSelector(selectShared, substate => substate.loadingVehiclesPosition);

const makeGetVehicleSensors = () =>
    createSelector(selectShared, substate => substate.vehicleSensors);

const makeGetVehicleSensorsById = () =>
    createSelector(selectShared, substate => substate.vehicleSensorsById);

const makeSelectVisibleTrackingInfo = () =>
    createSelector(selectShared, substate => substate.visibleTrackingInfo);

const makeSelectTrackSegmentUUID = () =>
    createSelector(selectShared, substate => substate.trackSegmentUUID);

const makeSelectTrackSegmentEvent = () =>
    createSelector(selectShared, substate => substate.trackSegmentEvent);

const makeSelectFollowVehicleId = () =>
    createSelector(selectShared, substate => substate.followVehicleId);

const makeSelectIsVisibleGeozonesLayer = () =>
    createSelector(selectShared, substate => substate.isVisibleGeozonesLayer);

const makeSelectVisibleVehicleList = () =>
    createSelector(selectShared, substate => substate.visibleVehicleList);

const makeSelectVisibleMobileMenuBtn = () =>
    createSelector(selectShared, substate => substate.visibleMobileMenuBtn);

const makeSelectCRUDMode = () =>
    createSelector(selectShared, substate => substate.CRUD_Mode);

const makeSelectVehiclePosition = () =>
    createSelector(selectShared, substate => substate.position);

const makeSelectRawTrackForce = () =>
    createSelector(selectShared, substate => substate.rawTrackForce);

const makeSelectRawTrackPosition = () =>
    createSelector(selectShared, substate => substate.rawTrackPosition);

const makeSelectGeofenceEditableLayer = () =>
    createSelector(selectShared, substate => substate.geofenceEditableLayer);

const makeSelectLoadingFile = () =>
    createSelector(selectShared, substate => substate.loadingFile);

const makeSelectLoadingFileName = () =>
    createSelector(selectShared, substate => substate.loadingFileName);

const makeSelectImportProgress = () =>
    createSelector(selectShared, substate => substate.importProgress);

const makeSelectOpenImportList = () =>
    createSelector(selectShared, substate => substate.openImportList);

const makeSelectImportListInProgress = () =>
    createSelector(selectShared, substate => substate.importListInProgress);

const makeSelectImportHistory = () =>
    createSelector(selectShared, substate => substate.importHistory);

const makeSelectDrawMode = () =>
    createSelector(selectShared, substate => substate.drawMode);

const makeSelectScrollPosition = () =>
    createSelector(selectShared, substate => substate.scrollPosition);

const makeSelectPendingRefreshToken = () =>
    createSelector(selectShared, substate => substate.pendingRefreshToken);

const makeSelectAccessibleCustomers = () =>
    createSelector(selectShared, substate => substate.accessibleCustomers);

const makeSelectLastSelectedVehicleId = () =>
    createSelector(selectShared, substate => substate.lastSelectedVehicleId);

const makeSelectLastSelectedVehicleState = () =>
    createSelector(selectShared, substate => substate.lastSelectedVehicleState);

export {
    selectShared,
    makeSelectLanguages,
    makeSelectServerConnectionStatus,
    makeSelectInitServerConnection,
    makeSelectCurrentUser,
    makeSelectCurrentUserGeolocation,
    makeSelectCurrentCustomer,
    makeSelectVehicles,
    makeSelectVehiclesParams,
    makeSelectVehicleId,
    makeSelectVehiclesTails,
    makeSelectVehicle,
    makeSelectSwipedVehicle,
    makeSelectVehicleDriverList,
    makeSelectTags,
    makeSelectVehicleTagParams,
    makeSelectTag,
    makeSelectDeleteTagId,
    makeSelectIsOpenTags,
    makeSelectIsOpenGroups,
    makeSelectLoading,
    makeSelectFreeze,
    makeSelectExpand,
    makeGetVehicleAddress,
    makeGetVehicleCurrentPosition,
    makeSelectTrackUUID,
    makeSelectVehiclesTracks,
    makeSelectTrackParams,
    makeSelectEmptyTrack,
    makeSelectVisibleStream,
    makeSelectVisibleMonitoringInfo,
    makeSelectLastPingTimestamp,
    makeSelectGeofenceList,
    makeSelectGeofenceParams,
    makeSelectGeofence,
    makeSelectGeofenceCRUDMode,
    makeSelectStartDrawGeofence,
    makeSelectVisibleGeofenceInfo,
    makeSelectVehicleGeofenceList,
    makeSelectEditGeofenceId,
    makeSelectIsEditGeofence,
    makeSelectVehicleListAttachedToGeofence,
    makeSelectVehicleAmountInsideOutsideToGeofence,
    makeSelectEditedGeofenceRadius,
    makeSelectCheckedVehiclesPositions,
    makeSelectCheckedVehiclesPositionsParams,
    makeGetVehicleSensors,
    makeSelectVisibleTrackingInfo,
    makeSelectTrackSegmentUUID,
    makeSelectLoadingVehiclesPositions,
    makeSelectTrackSegmentEvent,
    makeSelectFollowVehicleId,
    makeSelectIsVisibleGeozonesLayer,
    makeSelectVisibleVehicleList,
    makeSelectVisibleMobileMenuBtn,
    makeSelectCRUDMode,
    makeSelectVehiclePosition,
    makeSelectRawTrackForce,
    makeSelectRawTrackPosition,
    makeSelectGeofenceEditableLayer,
    makeSelectLoadingFile,
    makeSelectLoadingFileName,
    makeSelectImportProgress,
    makeSelectOpenImportList,
    makeSelectImportListInProgress,
    makeSelectImportHistory,
    makeSelectDrawMode,
    makeGetVehicleSensorsById,
    makeSelectScrollPosition,
    makeSelectPendingRefreshToken,
    makeSelectAccessibleCustomers,
    makeSelectUserLoginStatus,
    makeSelectLastSelectedVehicleId,
    makeSelectLastSelectedVehicleState
};