enum ActionTypes {
    CLEAR_STATE = 'settingsShared/CLEAR_STATE',
    SWITCH_LEFT_MENU_ITEM = 'settingsShared/SWITCH_LEFT_MENU_ITEM',
    SWITCH_RIGHT_MENU_ITEM = 'settingsShared/SWITCH_RIGHT_MENU_ITEM',
    UPDATE_USER_INFO = 'settingsShared/UPDATE_USER_INFO',
    CREATE_UPDATE_USER = 'settingsShared/CREATE_UPDATE_USER',
    UPDATE_ACCESS_USER = 'settingsShared/UPDATE_ACCESS_USER',
    GET_USER = 'settingsShared/GET_USER',
    DELETE_USER = 'settingsShared/DELETE_USER',
    GET_LIST_USER = 'settingsShared/GET_LIST_USER',
    UPDATE_LIST_USER = 'settingsShared/UPDATE_LIST_USER',
    UPDATE_LINKED_VEHICLE_LIST_BY_DRIVER_ID = 'settingsShared/UPDATE_LINKED_VEHICLE_LIST_BY_DRIVER_ID',
    SET_USER_ID = "settingsShared/SET_USER_ID",
    SELECT_USER = 'settingsShared/SELECT_USER',
    REMOVE_USER = 'settingsShared/REMOVE_USER',
    REMOVE_DRIVER = 'settingsShared/REMOVE_DRIVER',
    SET_USER_CARS = 'settingsShared/SET_USER_CARS',
    UPDATE_USER_DEALER = 'settingsShared/UPDATE_USER_DEALER',
    SET_USER_DEALER = 'settingsShared/SET_USER_DEALER',

    ADD_USER = 'settingsShared/ADD_USER',
    GET_INFO_ACCESS_USER = 'settingsShared/GET_INFO_ACCESS_USER',
    UPDATE_INFO_ACCESS_USER = 'settingsShared/UPDATE_INFO_ACCESS_USER',
    GET_LIST_DRIVER = 'settingsShared/GET_LIST_DRIVER',
    SET_LIST_DRIVER_QUERY_PARAMS = 'settingsShared/SET_LIST_DRIVER_QUERY_PARAMS',
    SET_DRIVER_ID = "settingsShared/SET_DRIVER_ID",
    UPDATE_LIST_DRIVER = 'settingsShared/UPDATE_LIST_DRIVER',
    SELECT_DRIVER = 'settingsShared/SELECT_DRIVER',
    SELECT_DRIVER_ACCESS = 'settingsShared/SELECT_DRIVER_ACCESS',
    ADD_DRIVER = 'settingsShared/ADD_DRIVER',
    GET_DRIVER = 'settingsShared/GET_DRIVER',
    UPDATE_DRIVER_INFO = 'settingsShared/UPDATE_DRIVER_INFO',
    CREATE_UPDATE_DRIVER = 'settingsShared/CREATE_UPDATE_DRIVER',
    SET_FULL_ACCESS = 'settingsShared/SET_FULL_ACCESS',
    UPDATE_ACCESS = 'settingsShared/UPDATE_ACCESS',
    SET_VIEW_ACCESS = 'settingsShared/SET_VIEW_ACCESS',
    RESPONSE_CREATE_USER = 'settingsShared/RESPONSE_CREATE_USER',
    UPDATE_LOAD = 'settingsShared/UPDATE_LOAD',
    SET_SORT_USER_LIST = 'settingsShared/SET_SORT_USER_LIST',
    UPDATE_TYPE_RIGHT_DRIVER = 'settings/UPDATE_TYPE_RIGHT_DRIVER',
    GET_LINKED_VEHICLE_LIST_BY_DEALER_ID = 'settingsShared/GET_LINKED_VEHICLE_LIST_BY_DEALER_ID',
    UNLINK_VEHICLE_FROM_DRIVER = 'settingsShared/UNLINK_VEHICLE_FROM_DRIVER'
}

export default ActionTypes;