import React from "react";
import { useSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const SnackbarCloseButton = (props: { snackbarKey: any }) => {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton onClick={() => closeSnackbar(props.snackbarKey)}>
            <CloseIcon fontSize="small" style={{ color: '#ffffff' }} />
        </IconButton>
    )
}
