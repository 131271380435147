import IconCopy from "../../assets/icons/actions/copy.svg";
import IconPdf from "../../assets/icons/actions/pdf.svg";
import IconPrint from "../../assets/icons/actions/print.svg";

export const menuRightTabs = {
    user: [
        { label: "account_settings.main", value: "main", content: "tabUser" },
        { label: "settings.access", value: "access", content: "tabUserAccess" },
    ],
    driver: [],
    object: [
        { label: "account_settings.main", value: "main", content: "tabVehicleEdit" },
        { label: "settings.object.technical_parameters", value: "technical", content: "tabVehicleTechnical" },
        { label: "settings.access.sensors", value: "sensors", content: 'tabVehicleSensor' },
        { label: "settings.access.sensor_visualisation", value: "visualisation", content: 'tabVehicleSensorVisualisation' },
        // { label: "settings.access.maintenance", value: "maintenance", content: '' },
    ],
    tracker: [
        { label: "settings.device.settings", value: "main", content: "tabDeviceEdit" },
        { label: "settings.device.commands", value: "commands", content: "tabDeviceCommands" },
    ],
    account: [],
    events: [
        // { label: "settings.adjustment.events_table", value: "main", content: "tabEventsTable" },
        // { label: "settings.adjustment.notifications_channels", value: "channels", content: "tabChannels" },
    ],
    channels: [],
} as const;

// export const menuRightTabsClient = {
//     user: [
//         { label: "account_settings.main", value: "main", content: "tabUser" },
//         { label: "settings.access", value: "access", content: "tabUserAccess" },
//         { label: "settings.dealer_access", value: "dealer", content: "tabDealerAccess" }
//     ],
//     driver: [],
//     object: [
//         { label: "account_settings.main", value: "main", content: "tabVehicleEdit" },
//         { label: "settings.object.technical_parameters", value: "technical", content: "tabVehicleTechnical" },
//         { label: "settings.access.sensors", value: "sensors", content: 'tabVehicleSensor' },
//         { label: "settings.access.sensor_visualisation", value: "visualisation", content: 'tabVehicleSensorVisualisation' },
//         { label: "settings.access.maintenance", value: "maintenance", content: '' },
//     ],
//     tracker: [
//         { label: "settings.device.settings", value: "main", content: "tabDeviceEdit" },
//         { label: "settings.device.commands", value: "commands", content: "tabDeviceCommands" },
//     ],
//     account: [],
//     events: [
//         { label: "settings.adjustment.events_table", value: "main", content: "tabEventsTable" },
//         { label: "settings.adjustment.notifications_channels", value: "channels", content: "tabChannels" },
//     ],
// } /*as const*/;

export const menuLeftTabs = [
    {
        label: "settings.user",
        value: "user",
        menuRightTab: menuRightTabs['user'][0].value,
        content: "tabUser",
        privilege: "user.view"
    },
    {
        label: "settings.driver",
        value: "driver",
        content: "tabDriver",
        menuRightTab: "driver.add",
        privilege: "driver.view"
    },
    {
        label: "settings.object",
        value: "object",
        content: "tabObject",
        menuRightTab: menuRightTabs['object'][0].value,
        privilege: "vehicle.view"
    },
    {
        label: "settings.tracker",
        value: "tracker",
        content: "tabDevice",
        menuRightTab: menuRightTabs['tracker'][0].value,
        privilege: "track.view"
    },
    {
        label: "settings.adjustment",
        value: "adjustment",
        content: "tabAdjustment",
        menuRightTab: "",
        privilege: "maintenance.view"
    },
] as const;

export const sortControlBlockDrivers = [
    { id: 'name', name: 'sort.alphabetically', checked: true },
    { id: 'created_at', name: 'sort.by_time_of_entry', checked: false },
];

export const sortControlBlock = [
    // { id: 'name', name: 'sort.alphabetically', checked: true },
    { id: 'is_super', name: 'sort.alphabetically', checked: true },
    { id: 'created_at', name: 'sort.by_time_of_entry', checked: false },
    /* { id: 'groups_end', name: 'sort.groups_at_the_end', checked: false } */
];

export const tableRightView = [
    { id: "administration", name: "settings.access.administration", code: "admin", privileges: {} },
    { id: "monitoring", name: "settings.access.monitoring", code: "monitor", privileges: {} },
    { id: "trekking", name: "settings.access.trekking", code: "track", privileges: {} },
    { id: "geozone", name: "settings.access.geozone", code: "geofence", privileges: {} },
    { id: "logistics", name: "settings.access.logistics", code: "logistic", privileges: {} },
    { id: "settings", name: "settings.access.settings", code: "setup", privileges: {} },
    { id: "sensors", name: "settings.access.sensors", code: "sensors", privileges: {} },
    { id: "reporting", name: "settings.access.reporting", code: "report", privileges: {} },
    { id: "maintenance", name: "settings.access.maintenance", code: "maintenance", privileges: {} },
    // { id: "billing", name: "settings.access.billing", code: "billing", privileges: {} },
    { id: "alarm", name: "settings.access.alarm", code: "alarm", privileges: {} },
];

// export const tableRightViewDealer = [
//     { id: "administration", name: "settings.access.administration", code: "admin", privileges: {admin: {nameKey: "module.admin", enabled: false}} },
//     { id: "monitoring", name: "settings.access.monitoring", code: "monitor", privileges: {monitor: {nameKey: "module.monitor", enabled: false}} },
//     { id: "trekking", name: "settings.access.trekking", code: "track", privileges: {track: {nameKey: "module.track", enabled: false}} },
//     { id: "geozone", name: "settings.access.geozone", code: "geofence", privileges: {geofence: {nameKey: "module.geofence", enabled: false}} },
//     // { id: "logistics", name: "settings.access.logistics", code: "logistic", privileges: {} },
//     { id: "settings", name: "settings.access.settings", code: "setup", privileges: {setup: {nameKey: "module.setup", enabled: false}} },
//     // { id: "sensors", name: "settings.access.sensors", code: "sensors", privileges: {} },
//     { id: "reporting", name: "settings.access.reporting", code: "report", privileges: {report: {nameKey: "module.report", enabled: false}} },
//     // { id: "maintenance", name: "settings.access.maintenance", code: "maintenance", privileges: {} },
//     // { id: "billing", name: "settings.access.billing", code: "billing", privileges: {} },
//     { id: "alarm", name: "settings.access.alarm", code: "alarm", privileges: {alarm: {nameKey: "module.alarm", enabled: false}} },
// ];

export const tableRightChange = [
    // { id: "add_edit_customers", name: "settings.access.add_edit_customers", parentCode: "module.admin", privileges: {}, codes: ["customer.edit", "customer.del"] },
    { id: "add_edit_users", name: "settings.access.add_edit_users", parentCode: "module.setup", privileges: {}, codes: ["user.view", "user.edit", "user.del"], module: "setup" },
    { id: "add_edit_drivers", name: "settings.access.add_edit_drivers", parentCode: "module.setup", privileges: {}, codes: ["driver.view", "driver.edit", "driver.del"], module: "setup" },
    { id: "add_edit_objects", name: "settings.access.add_edit_objects", parentCode: "module.setup", privileges: {}, codes: ["vehicle.view", "vehicle.edit", "vehicle.del"], module: "setup" },
    { id: "add_edit_geozones", name: "settings.access.add_edit_geozones", parentCode: "module.geofence", privileges: {}, codes: ["geofence.view", "geofence.edit", "geofence.del"], module: "geofence" },
    { id: "add_edit_trips", name: "settings.access.add_edit_trips", parentCode: "module.logistic", privileges: {}, codes: ["route.view", "route.edit", "route.del"], module: "logistic" },
    { id: "add_edit_sensors", name: "settings.access.add_edit_sensors", parentCode: "module.setup", privileges: {}, codes: ["sensor.view", "sensor.edit", "sensor.del"], module: "setup" },
    { id: "add_edit_groups", name: "settings.access.add_edit_groups", parentCode: "module.setup", privileges: {}, codes: ["group.view", "group.edit", "group.del"], module: "setup" },
    { id: "add_edit_maintenance", name: "settings.access.add_edit_maintenance", parentCode: "module.maintenance", privileges: {}, codes: ["maintenance.view", "maintenance.edit", "maintenance.del"], module: "maintenance" },
    { id: "add_edit_reports", name: "settings.access.add_edit_reports", parentCode: "module.report", privileges: {}, codes: ["report.view", "report.edit", "report.del"], module: "report" },
    { id: "add_edit_commands", name: "settings.access.add_edit_commands", parentCode: "module.setup", privileges: {}, codes: ["command.view", "command.edit", "command.del"], module: "setup" },
    { id: "add_edit_trackers", name: "settings.access.add_edit_trackers", parentCode: "module.setup", privileges: {}, codes: ["device.view", "device.edit", "device.del"], module: "setup" },
    { id: "add_edit_customers", name: "settings.access.add_edit_customers", parentCode: "module.admin", privileges: {}, codes: ["customer.view", "customer.edit", "customer.del"], module: "admin" },
    { id: "add_edit_tracks", name: "settings.access.add_edit_tracks", parentCode: "module.track", privileges: {}, codes: ["track.view", "track.edit", "track.del"], module: "track" },
    { id: "add_edit_tariffs", name: "settings.access.add_edit_tariff_plans", parentCode: "module.admin", privileges: {}, codes: ["tariff.view", "tariff.edit", "tariff.del"], module: "admin" },
];

// export const tableRightChangeDealer = [
//     { id: "add_edit_users", name: "settings.access.add_edit_users", parentCode: "module.setup", privileges: {
//             "user.del": {nameKey: "privilege.user.del", enabled: false},
//             "user.edit": {nameKey: "privilege.user.edit", enabled: false},
//             "user.view": {nameKey: "privilege.user.view", enabled: false}
//         }, codes: ["user.view", "user.edit", "user.del"], module: "setup" },
//     { id: "add_edit_drivers", name: "settings.access.add_edit_drivers", parentCode: "module.setup", privileges: {
//             "driver.del": {nameKey: "privilege.driver.del", enabled: false},
//             "driver.edit": {nameKey: "privilege.driver.edit", enabled: false},
//             "driver.view": {nameKey: "privilege.driver.view", enabled: false}
//         }, codes: ["driver.view", "driver.edit", "driver.del"], module: "setup" },
//     { id: "add_edit_objects", name: "settings.access.add_edit_objects", parentCode: "module.setup", privileges: {
//             "vehicle.del": {nameKey: "privilege.vehicle.del", enabled: false},
//             "vehicle.edit": {nameKey: "privilege.vehicle.edit", enabled: false},
//             "vehicle.view": {nameKey: "privilege.vehicle.view", enabled: false}
//         }, codes: ["vehicle.view", "vehicle.edit", "vehicle.del"], module: "setup" },
//     { id: "add_edit_geozones", name: "settings.access.add_edit_geozones", parentCode: "module.geofence", privileges: {
//             "geofence.del": {nameKey: "privilege.geofence.del", enabled: false},
//             "geofence.edit": {nameKey: "privilege.geofence.edit", enabled: false},
//             "geofence.view": {nameKey: "privilege.geofence.view", enabled: false}
//         }, codes: ["geofence.view", "geofence.edit", "geofence.del"], module: "geofence" },
//     { id: "add_edit_trips", name: "settings.access.add_edit_trips", parentCode: "module.logistic", privileges: {
//             "route.del": {nameKey: "privilege.route.del", enabled: false},
//             "route.edit": {nameKey: "privilege.route.edit", enabled: false},
//             "route.view": {nameKey: "privilege.route.view", enabled: false}
//         }, codes: ["route.view", "route.edit", "route.del"], module: "logistic" },
//     { id: "add_edit_sensors", name: "settings.access.add_edit_sensors", parentCode: "module.setup", privileges: {
//             "sensor.del": {nameKey: "privilege.sensor.del", enabled: false},
//             "sensor.edit": {nameKey: "privilege.sensor.edit", enabled: false},
//             "sensor.view": {nameKey: "privilege.sensor.view", enabled: false}
//         }, codes: ["sensor.view", "sensor.edit", "sensor.del"], module: "setup" },
//     { id: "add_edit_groups", name: "settings.access.add_edit_groups", parentCode: "module.setup", privileges: {
//             "group.del": {nameKey: "privilege.group.del", enabled: false},
//             "group.edit": {nameKey: "privilege.group.edit", enabled: false},
//             "group.view": {nameKey: "privilege.group.view", enabled: false}
//         }, codes: ["group.view", "group.edit", "group.del"], module: "setup" },
//     { id: "add_edit_maintenance", name: "settings.access.add_edit_maintenance", parentCode: "module.maintenance", privileges: {
//             "maintenance.del": {nameKey: "privilege.maintenance.del", enabled: false},
//             "maintenance.edit": {nameKey: "privilege.maintenance.edit", enabled: false},
//             "maintenance.view": {nameKey: "privilege.maintenance.view", enabled: false}
//         }, codes: ["maintenance.view", "maintenance.edit", "maintenance.del"], module: "maintenance" },
//     { id: "add_edit_reports", name: "settings.access.add_edit_reports", parentCode: "module.report", privileges: {
//             "report.del": {nameKey: "privilege.report.del", enabled: false},
//             "report.edit": {nameKey: "privilege.report.edit", enabled: false},
//             "report.view": {nameKey: "privilege.report.view", enabled: false}
//         }, codes: ["report.view", "report.edit", "report.del"], module: "report" },
//     { id: "add_edit_commands", name: "settings.access.add_edit_commands", parentCode: "module.setup", privileges: {
//             "command.del": {nameKey: "privilege.command.del", enabled: false},
//             "command.edit": {nameKey: "privilege.command.edit", enabled: false},
//             "command.view": {nameKey: "privilege.command.view", enabled: false}
//         }, codes: ["command.view", "command.edit", "command.del"], module: "setup" },
//     { id: "add_edit_trackers", name: "settings.access.add_edit_trackers", parentCode: "module.setup", privileges: {
//             "device.del": {nameKey: "privilege.device.del", enabled: false},
//             "device.edit": {nameKey: "privilege.device.edit", enabled: false},
//             "device.view": {nameKey: "privilege.device.view", enabled: false}
//         }, codes: ["device.view", "device.edit", "device.del"], module: "setup" },
//     { id: "add_edit_customers", name: "settings.access.add_edit_customers", parentCode: "module.admin", privileges: {
//             "customer.del": {nameKey: "privilege.customer.del", enabled: false},
//             "customer.edit": {nameKey: "privilege.customer.edit", enabled: false},
//             "customer.view": {nameKey: "privilege.customer.view", enabled: false}
//         }, codes: ["customer.view", "customer.edit", "customer.del"], module: "admin" },
//     { id: "add_edit_tracks", name: "settings.access.add_edit_tracks", parentCode: "module.track", privileges: {
//             "track.del": {nameKey: "privilege.track.del", enabled: false},
//             "track.edit": {nameKey: "privilege.track.edit", enabled: false},
//             "track.view": {nameKey: "privilege.track.view", enabled: false}
//         }, codes: ["track.view", "track.edit", "track.del"], module: "track" },
//     { id: "add_edit_tariffs", name: "settings.access.add_edit_tariff_plans", parentCode: "module.admin", privileges: {
//             "tariff.del": {nameKey: "privilege.tariff.del", enabled: false},
//             "tariff.edit": {nameKey: "privilege.tariff.edit", enabled: false},
//             "tariff.view": {nameKey: "privilege.tariff.view", enabled: false}
//         }, codes: ["tariff.view", "tariff.edit", "tariff.del"], module: "admin" },
// ];


export const tableRightElementTrack = [
    { id: "over_speed", name: "settings.access.over_speed", privileges: {}, codes: ["track.overspeed"] },
    { id: "geofence_violation", name: "settings.access.geofence_violation", privileges: {}, codes: ["track.geofence.violation"] },
    { id: "gas_stations", name: "settings.access.gas_stations", privileges: {}, codes: ["track.refueling"] },
    { id: "fuel_drains", name: "settings.access.fuel_drains", privileges: {}, codes: ["track.fueldrain"] },
    { id: "stops_parking", name: "settings.access.stops_parking", privileges: {}, codes: ["track.stops"] },
];

// export const tableRightElementTrackDealer = [
//     { id: "over_speed", name: "settings.access.over_speed", privileges: {"track.overspeed": {nameKey: "privilege.track.overspeed", enabled: false}}, codes: ["track.overspeed"] },
//     { id: "geofence_violation", name: "settings.access.geofence_violation", privileges: {"track.geofence.violation": {nameKey: "privilege.track.geofence.violation", enabled: false}}, codes: ["track.geofence.violation"] },
//     { id: "gas_stations", name: "settings.access.gas_stations", privileges: {"track.refueling": {nameKey: "privilege.track.refueling", enabled: false}}, codes: ["track.refueling"] },
//     { id: "fuel_drains", name: "settings.access.fuel_drains", privileges: {"track.fueldrain": {nameKey: "privilege.track.fueldrain", enabled: false}}, codes: ["track.fueldrain"] },
//     { id: "stops_parking", name: "settings.access.stops_parking", privileges: {"track.stops": {nameKey: "privilege.track.stops", enabled: false}}, codes: ["track.stops"] },
// ];

export const tableRightControl = [
    { id: "sending_commands", name: "settings.access.sending_commands", privileges: {}, codes: ["command.send"] },
    { id: "communication_object", name: "settings.access.communication_object", privileges: {}, codes: ["vehicle.voice"] },
    { id: "viewing_video_object", name: "settings.access.viewing_video_object", privileges: {}, codes: ["vehicle.video"] },
    { id: "view_account_information", name: "settings.access.view_account_information", privileges: {}, codes: ["customer.edit_self"] },
    { id: "view_vehicle_all", name: "settings.access.view_vehicle_all", privileges: {}, codes: ["vehicle.view_all"] },

];

// export const tableRightControlDealer = [
//     { id: "sending_commands", name: "settings.access.sending_commands", privileges: {"command.send": {nameKey: "privilege.command.send", enabled: false}}, codes: ["command.send"] },
//     { id: "communication_object", name: "settings.access.communication_object", privileges: {"vehicle.voice": {nameKey: "privilege.vehicle.voice", enabled: false}}, codes: ["vehicle.voice"] },
//     { id: "viewing_video_object", name: "settings.access.viewing_video_object", privileges: {"vehicle.video": {nameKey: "privilege.vehicle.video", enabled: false}}, codes: ["vehicle.video"] },
//     { id: "view_account_information", name: "settings.access.view_account_information", privileges: {"customer.edit_self": {nameKey: "privilege.customer.edit_self", enabled: false}}, codes: ["customer.edit_self"] },
//     { id: "view_vehicle_all", name: "settings.access.view_vehicle_all", privileges: {"vehicle.view_all": {nameKey: "privilege.vehicle.view_all", enabled: false}}, codes: ["vehicle.view_all"] },
// ];

// alarm.switch
// :
// {nameKey: "privilege.alarm.switch", enabled: true}
// event.del
// :
// {nameKey: "privilege.event.del", enabled: true}
// event.edit
// :
// {nameKey: "privilege.event.edit", enabled: true}
// event.view
// :
// {nameKey: "privilege.event.view", enabled: true}
// tag.del
// :
// {nameKey: "privilege.tag.del", enabled: true}
// tag.edit
// :
// {nameKey: "privilege.tag.edit", enabled: true}
// tag.view
// :
// {nameKey: "privilege.tag.view", enabled: true}
//


export const moreHeaderBlock = [
    { id: 'copy', name: 'button.copy', icon: IconCopy },
    { id: 'exportPdf', name: 'button.export_pdf', icon: IconPdf },
    { id: 'print', name: 'button.print', icon: IconPrint }
];