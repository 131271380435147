import cx from "../services/utils/cx";
import styles from "./Login.module.css";
import React from "react";

const Page404 = (p: {
    id?: string,
    className?: string
}) => {
    const id = p.id ? p.id : 'login';

    const className = cx(
        styles.container,
        p.className
    );

    return (
        <div id={id} className={className}>
            <div className={cx(styles.backgroundImage, styles.backgroundImageWrapper)}>
                <div className={cx(styles.backgroundImage, styles.backgroundImageEagle)} />
                <div className={cx(styles.backgroundImage, styles.backgroundImageVector)} />
            </div>
            <div className={styles.formWrapper}>
                <div className={styles.logoWrapperMobile}>
                    <div className="column-alignment-center">
                        <div className={cx(styles.backgroundImage, styles.backgroundImageEagle)} />
                    </div>
                </div>
                <div className={cx(styles.rowCenter)}>
                    <h1 style={{fontSize: '102px', color: '#e6e6e6', fontWeight: '800'}}>404</h1>
                    {/*{i18next.t("login.login")}*/}
                </div>
                <div className="row-alignment-center">
                    <h3 style={{fontWeight: '500'}}>Page Not Found</h3>
                </div>
                <div className="row-alignment-center">
                    <div style={{color: '#676a6c', fontSize: '13px', textAlign: 'center'}}>
                        Sorry, but the page you are looking for has not been found. Try checking your <span style={{fontWeight: 600}}>rights</span> and <a style={{color: "#03a9f4"}} href="/login">login</a> as <span style={{fontWeight: 600}}>admin or user</span> in our app.
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Page404;
