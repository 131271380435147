import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import { BrowserRouter /*HashRouter*/ } from "react-router-dom";
import configureStore from "./services/utils/configureStore";
import { history } from "./services/utils/history";
// import { SnackbarProvider } from "notistack";
// import { NotificationServiceConfigurator } from "./services/notification/NotificationService";
// import { StorageServiceDispatchConfigurator } from "./settings/storageService";
import InterruptedConnectionNotification from "./services/notification/InterruptedConnectionNotification";
// import ViewPortProvider from "./providers/ViewPortProvider";
// import { SnackbarCloseButton } from "./components/clickable/SnackbarCloseButton";
import DownloadNotification from "./services/notification/DownloadNotification";
// import { createStructuredSelector } from "reselect";
// import { makeSelectLoadingFile } from "./shared/selectors";

const initialState = {};
const store = configureStore(initialState, history);

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <BrowserRouter>
                {/*    <HashRouter>*/}
                {/*<ViewPortProvider>*/}
                {/*    <SnackbarProvider*/}
                {/*        action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey}/>}*/}
                {/*        classes={{*/}
                {/*            root: styles.notificationTopFirst*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <NotificationServiceConfigurator/>*/}
                {/*        <StorageServiceDispatchConfigurator/>*/}
                {/*        <App/>*/}
                {/*    </SnackbarProvider>*/}
                {/*</ViewPortProvider>*/}
                <App/>
                <InterruptedConnectionNotification/>
                <DownloadNotification/>
                {/*</HashRouter>*/}
            </BrowserRouter>
        </React.StrictMode>
    </Provider>,
    document.getElementById("root") as HTMLElement,
);
