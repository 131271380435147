
export class LazyLoadingHelper {
    public static lazyReloadOnFail(fn: any): Promise<any> {
        return new Promise(resolve => {
            fn()
                .then(resolve)
                .catch(() => {
                    window.location.reload();
                });
        });
    }
}