import { useSnackbar, VariantType, WithSnackbarProps } from "notistack";
import React from "react";
import obj from "../utils/obj";
import i18next from "i18next";

const options = {
    autoHideDuration: 5000,
    anchorOrigin: { horizontal: "right", vertical: "top" },
};

let useSnackbarRef: WithSnackbarProps;
export const NotificationServiceConfigurator: React.FC = () => {
    useSnackbarRef = useSnackbar();
    return null;
};

const parseErrorMessage = (msg: any) => {
    if (msg.code) {
        return i18next.t(msg.code, msg.params);
    } else if (msg.details) return msg.details;
    else if (msg.message) return msg.message;
    else return "Error";
};

export default {
    success(msg: string) {
        this.notify(msg, "success");
    },

    warning(msg: string) {
        this.notify(msg, "warning");
    },

    info(msg: string) {
        this.notify(msg, "info");
    },

    error(msg: any) {
        this.notify(parseErrorMessage(msg), "error");
    },

    persist(msg: string, variant: VariantType = "info") {
        useSnackbarRef.enqueueSnackbar(msg,
            obj.getDeepCloneObject({ ...{persist: true, anchorOrigin: { horizontal: "right", vertical: "top" }}, variant}));
    },

    notify(msg: string, variant: VariantType = "default") {
        useSnackbarRef.enqueueSnackbar(msg, obj.getDeepCloneObject({ ...options, variant }));
    },

    clearNotifications(): void {
        useSnackbarRef.closeSnackbar();
    }
};
